export const isAdminCheck = () => {
    const user = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
    return user.role === 'Admin' ? true : false
}

export const isSuperAdminCheck = () => {
    const user = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
    return user.role === 'SuperAdmin' ? true : false
}

export const getUserDetails = () => {
    const user = JSON.parse(sessionStorage.getItem('userDetails') || '{}')
    return user
}
