import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First Name is required')
        .max(15, 'Must be 15 characters or less'),
    lastName: Yup.string()
        .required('Last Name is required')
        .max(15, 'Must be 15 characters or less'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    currentPassword: Yup.string().required('Current Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
        .required('Please confirm your password'),
})
