import React, { useEffect, useMemo, useState } from 'react'
import {
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
    HttpCallDelete,
} from '../../utils/Services/NetworkCall'
import CustomButton from '../../utils/CustomButton/CustomButton'
import Loader from '../../utils/Loader/Loader'
import CustomTable from '../../utils/CustomTable/CustomTable'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import { Form } from 'react-bootstrap'
import { ToastOnSuccess, ToastOnFailure } from '../../utils/Toast/Toast'
import Pagination from 'react-js-pagination'
import {
    ConfirmDelete,
    ConfirmDeleteWithAction,
    ConfirmToggleStatus,
} from '../../utils/ConfirmAlerts'
import RestSlider from '../RestaurantCategory/slider/RestSlider'
import SubHeader from '../../component/subHeader/SubHeader'
import { paginationHandler, showPageSize } from '../../utils/util'
import config from '../../configs/config'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import { modifyWrhLocationHandler } from '../inventory/methodHandler'

import './Admin.style.scss'

function Admin(props: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const [catergoryData, setCategoryData] = useState<any>([])
    const [show, setShow] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<any>(10)
    const [total, setTotal] = useState<number>(0)
    const [updateData, setupdateData] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [isUpdate, setIsUpdate] = useState<boolean>(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const [containerShow, setContainerShow] = useState<boolean>(false)

    const [restList, setRestList] = useState<any>([])

    const [restActiveList, setRestActiveList] = useState<any>([])

    const [activeId, setActiveId] = useState('')

    const [isFlag, setIsFlag] = useState<boolean>(false)

    const [flagBtnDisabled, setFlagBtnDisabled] = useState(false)

    const [locationData, setLocationData] = useState<any>([])
    const [selectedLocation, setSelectedLocation] = useState<any>([])

    const fetchAdminData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/get-admin-list?page=${currentPage}&pageSize=${pageSize},`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setCategoryData(res.data.data)
                setTotal(res.data.totalCount)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
    }

    const fetchLocationData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getLocationList?currentPage=${1}&pageSize=${1000}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setLocationData(dataHandler(res.data.data))
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const dataHandler = (data: any) => {
        return (
            data.length > 0 &&
            data.map((item: any) => ({
                ...item,
                terms: item?.termsAndCondition ? true : false,
            }))
        )
    }

    const addCategory = () => {
        setShow(true)
        setFirstName('')
        setLastName('')
        setEmail('')
        setSelectedLocation([])
        setIsUpdate(false)
    }

    const activateContainer = (data: any) => {
        setActiveId(data._id)
        setContainerShow(true)
    }

    const addAdminDetails = () => {
        if (firstName && lastName && email && selectedLocation.length > 0) {
            setLoading(true)
            setFlagBtnDisabled(true)
            HttpCallPost(
                `admin/add-admin-location`,
                {
                    firstName,
                    lastName,
                    email,
                    locationId: selectedLocation,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    if (res.data.status === 201) {
                        ToastOnSuccess(res.data.message)
                        setShow(false)
                        fetchAdminData()
                    } else {
                        ToastOnFailure(res.data.message)
                    }
                })
                .catch((err) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    ToastOnFailure(err.message)
                })
        } else {
            ToastOnFailure('Please fill all fields.')
        }
    }

    const updateAdminDetails = () => {
        if (firstName && lastName && email && selectedLocation.length > 0) {
            setFlagBtnDisabled(true)
            setLoading(true)
            HttpCallPUT(
                `admin/update-admin/${updateData._id}`,
                {
                    firstName,
                    lastName,
                    email,
                    locationId: selectedLocation,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                    fetchAdminData()
                })
                .catch((err) => {
                    setFlagBtnDisabled(false)
                    setLoading(false)
                    ToastOnFailure(err.message)
                })
        } else {
            ToastOnFailure('Please fill all fields.')
        }
    }

    const editCatergory = (data: any) => {
        setShow(true)
        setupdateData(data)
        setFirstName(data.firstName)
        setLastName(data.lastName)
        setEmail(data.email)
        setSelectedLocation(data.locationId)
        setIsUpdate(true)
    }

    const columns: any = useMemo(
        () => [
            {
                Header: 'First Name',
                accessor: 'firstName',
                Cell: ({ cell: { value } }: any) => <div>{value}</div>,
            },
            {
                Header: 'Last Name',
                accessor: 'lastName',
                Cell: ({ cell: { value } }: any) => <div>{value}</div>,
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: ({ cell: { value } }: any) => <div>{value}</div>,
            },
            {
                Header: 'Location',
                accessor: 'locationDetails',
                Cell: ({ cell: { value } }: any) => (
                    <p className="locationElips">
                        {fetchLocationDetails(value)}
                    </p>
                ),
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                Cell: ({
                    cell: {
                        row: { original },
                    },
                }: any) => (
                    <div className="Edit_Container">
                        <CustomButton
                            btnName="Edit"
                            clsName="editButton ms-2"
                            btnEvt={() => editCatergory(original)}
                        >
                            <i className="fas fa-edit"></i>
                        </CustomButton>
                        <CustomButton
                            btnName={
                                original.status === 1 ? 'Disable' : 'Enable'
                            }
                            clsName="Enable ms-2"
                            btnEvt={() =>
                                ConfirmToggleStatus(
                                    original,
                                    enableDisableAdmin
                                )
                            }
                        ></CustomButton>
                    </div>
                ),
            },
        ],
        []
    )

    const enableDisableAdmin = (data: any) => {
        setLoading(true)

        const adminId = data._id
        const newStatus = data.status === 1 ? 0 : 1
        HttpCallPUT(
            `admin/update-admin-status/${adminId}`,
            {
                status: newStatus,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)

                if (res.data.status === 200) {
                    ToastOnSuccess(res.data.message)
                    fetchAdminData()
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setLoading(false)
                ToastOnFailure(err.message)
            })
    }

    const handleCheckBoxChange = (item: any, event: any) => {
        const { checked } = event.target
        if (checked) {
            setSelectedLocation([...selectedLocation, item._id])
        } else {
            setSelectedLocation(
                selectedLocation.filter(
                    (checkedId: any) => checkedId !== item._id
                )
            )
        }
    }

    const locationChecked = function (item: any, list: any) {
        if (list) {
            const index = list.findIndex(function (val: any) {
                return val == item?._id
            })
            return index > -1
        }
    }

    useEffect(() => {
        fetchAdminData()
    }, [currentPage, pageSize, isFlag, props.activeUser])

    useEffect(() => {
        fetchLocationData()
    }, [])

    const fetchLocationDetails = (data: any) => {
        return data.length
            ? data.map((item: any) => item.name).join(', ')
            : 'NA'
    }

    return (
        <>
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <div className="Admin">
                    <SubHeader HeaderTitle="Admin" isShow={false} />
                    <div className="Add_Btn">
                        <CustomButton
                            clsName="AddAdmin ms-2 mt-2 mt-md-0"
                            btnName="Add Admin"
                            btnEvt={addCategory}
                        />
                    </div>

                    <div className="Charity_table table-responsive charityTableSec">
                        <CustomTable
                            msg="No Admin found"
                            columns={columns}
                            data={catergoryData || []}
                        />
                    </div>

                    <CustomSlider
                        activeTitle={isUpdate ? 'Edit' : 'Add'}
                        show={show}
                        setShow={setShow}
                        Evt={() => {
                            !flagBtnDisabled
                                ? isUpdate
                                    ? updateAdminDetails()
                                    : addAdminDetails()
                                : false
                        }}
                        btnEventName={isUpdate ? 'Update' : 'Add'}
                    >
                        <div>
                            <div className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={firstName}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={lastName}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div>
                                <Form.Label>Location</Form.Label>
                                <div className="locationDrop">
                                    {locationData?.length > 0 &&
                                        locationData.map((item: any) => {
                                            return (
                                                <div key={item._id}>
                                                    <Form.Check
                                                        className="locationFlex"
                                                        defaultChecked={locationChecked(
                                                            item,
                                                            selectedLocation
                                                        )}
                                                        label={item?.name}
                                                        value={item._id}
                                                        name="group1"
                                                        onChange={(e) =>
                                                            handleCheckBoxChange(
                                                                item,
                                                                e
                                                            )
                                                        }
                                                        type="checkbox"
                                                        id={item._id}
                                                    />
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </CustomSlider>

                    {containerShow && (
                        <RestSlider
                            show={containerShow}
                            setShow={setContainerShow}
                            restList={restList}
                            setRestList={setRestList}
                            restActiveList={restActiveList}
                            setRestActiveList={setRestActiveList}
                            currentPage={currentPage}
                            pageSize={pageSize}
                            activeId={activeId}
                            isFlag={isFlag}
                            setIsFlag={setIsFlag}
                        />
                    )}

                    <div className="col-md-12">
                        <div className="table__pagination__container text-center mt-5 mb-5">
                            {total > pageSize && (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={pageSize}
                                    totalItemsCount={total}
                                    onChange={(page: any) =>
                                        setCurrentPage(page)
                                    }
                                />
                            )}
                            {showPageSize(total) && (
                                <select
                                    className="pagination__select"
                                    value={pageSize}
                                    onChange={handlePageSizeChange}
                                >
                                    {[10, 20, 30].map((size) => (
                                        <option key={size} value={size}>
                                            Show {size}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="perPageItem">
                                <p>
                                    {paginationHandler(
                                        currentPage,
                                        pageSize,
                                        total
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Admin
