import React, { useEffect, useMemo, useRef, useState } from 'react'
import Loader from '../../utils/Loader/Loader'
import './station.style.scss'
import CustomButton from '../../utils/CustomButton/CustomButton'
import { Form } from 'react-bootstrap'
import { ToastOnFailure, ToastOnSuccess } from '../../utils/Toast/Toast'
import CustomSlider from '../../utils/CustomSlider/CustomSlider'
import {
    HttpCallGet,
    HttpCallPost,
    HttpCallPUT,
} from '../../utils/Services/NetworkCall'
import SearchLocationInput from '../../utils/SearchLocation/SearchLocationInput'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Pagination from 'react-js-pagination'
import '../inventory/inventory.style.scss'
import axios from 'axios'
import CustomDropdown from '../../utils/CustomDropdown/CustomDropdown'
import SubHeader from '../../component/subHeader/SubHeader'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'
import { ref } from 'yup'

import { modifyWrhLocationHandler } from '../inventory/methodHandler'

export default function Stations(props: any) {
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState<boolean>(false)
    const [total, setTotal] = useState<any>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const [searchField, setSearchfield] = useState('')
    const [locationObj, setLocationObj] = useState<any>({})
    const [tableData, setTableData] = useState<any>([])
    const [editData, setEditData] = useState<any>(null)
    const [dropActive, setDropActive] = useState<any>('')
    const [imageSrc, setImageSrc] = useState<any>('')
    const imgRef = useRef<any>(null)
    const locationRef = useRef<any>(null)
    const [flagDisabled, setFlagDisabled] = useState<boolean>(false)
    const [isFlagUpdate, setIsFlagUpdate] = useState<boolean>(true)
    const [locationData, setLocationData] = useState<any>([])

    const [selectedLocation, setSelectedLocation] = useState<any>('')

    const [locationId, setLocationId] = useState(null)

    const [stationDetails, setStationDetails] = useState<any>({
        name: '',
        qrCode: '',
        macAddress: '',
        isUpdate: '',
        imageSrc: '',
        isDisable: false,
        formatedAddres: '',
    })

    const DropDownDataValue = [
        { label: 'SMART', value: 1 },
        { label: 'SIMPLE', value: 2 },
    ]

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
        setCurrentPage(1)
    }

    const fetchLocationData = () => {
        setLoading(true)
        HttpCallGet(
            `admin/getLocationList?currentPage=${1}&pageSize=${1000}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setLocationData(dataHandler(res.data.data))
            })

            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const AddDropStation = () => {
        setShow(true)
        setStationDetails({
            ...stationDetails,
            name: '',
            qrCode: '',
            macAddress: '',
            isUpdate: 'add',
            imageSrc: '',
            isDisable: false,
            formatedAddres: '',
        })
        setDropActive('')
        setSelectedLocation('')
        imgRef.current.value = ''
    }

    const checkImage = () => {
        return (stationDetails.isUpdate === 'isUpdate' && imageSrc) ||
            stationDetails.imageSrc.length > 0
            ? true
            : false
    }

    const addStation = () => {
        if (dropActive.label === undefined) {
            ToastOnFailure('Please fill type field')
            return
        }
        if (stationDetails.name.length === 0) {
            ToastOnFailure('Please fill name field')
            return
        }
        if (stationDetails.qrCode.length === 0) {
            ToastOnFailure('Please fill QR-Code field')
            return
        }
        if (dropActive.label === 'SMART' && stationDetails.macAddress == '') {
            ToastOnFailure('Please fill macAddress field')
            return
        }

        if (Object.keys(locationObj).length === 0) {
            ToastOnFailure('Please enter address')
            return
        }

        if (!selectedLocation) {
            ToastOnFailure('Please enter location')
            return
        }

        if (stationDetails.imageSrc === '') {
            ToastOnFailure('Please upload an image')
            return
        }
        setStationDetails({
            ...stationDetails,
            formatedAddres: locationObj.formatted_address,
        })
        setFlagDisabled(true)

        setLoading(true)
        HttpCallPost(
            `admin/drop-location`,
            {
                formattedAddress: locationObj.formatted_address,
                locationId: selectedLocation._id,
                images: stationDetails.imageSrc,
                type: dropActive.label,
                macAddress: stationDetails.macAddress,
                name: stationDetails.name,
                qrCode: stationDetails.qrCode,
                placeId: locationObj.place_id,
                latitude: locationObj?.geometry?.location?.lat(),
                longitude: locationObj?.geometry?.location?.lng(),
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                setLoading(false)
                setFlagDisabled(false)

                if (res.data.status === 1) {
                    if (currentPage == 1) {
                        fetchDropStationData()
                    }
                    setCurrentPage(1)
                    setShow(false)
                    ToastOnSuccess(res.data.message)
                } else {
                    ToastOnFailure(res.data.message)
                }
            })
            .catch((err) => {
                setFlagDisabled(false)
                ToastOnFailure(err.message)
            })
    }

    const handleEdit = (item: any) => {
        setEditData(item)
        setShow(true)
        setStationDetails({
            ...stationDetails,
            name: item.name,
            qrCode: item.qrCode,
            macAddress: item.macAddress,
            isUpdate: 'update',
            imageSrc: item.images,
            isDisable: true,
            placeId: item.placeId,
            formatedAddres: item.address.formattedAddress,
            lat: item.geo.coordinates[1],
            lng: item.geo.coordinates[0],
        })
        setDropActive({
            ...dropActive,
            label: item.type,
        })
        setImageSrc(item.images)

        const location = {
            ...item.locationDetails,
            label: item.locationDetails.name,
            value: item.locationDetails._id,
        }
        setSelectedLocation(location)
    }

    const editStation = () => {
        if (dropActive.label == undefined) {
            ToastOnFailure('Please enter station type')
            return
        }
        if (
            dropActive.label == 'SMART' &&
            (stationDetails.macAddress == undefined ||
                stationDetails.macAddress == '')
        ) {
            ToastOnFailure('Please fill macAddress field')
            return
        }
        if (!isFlagUpdate) {
            ToastOnFailure('please enter location')
            return
        }
        if (
            stationDetails.name &&
            stationDetails.qrCode &&
            dropActive.label &&
            stationDetails.imageSrc
        ) {
            setFlagDisabled(true)
            setLoading(true)
            const locationLength = Object.keys(locationObj).length > 0
            HttpCallPUT(
                `admin/drop-location`,
                {
                    address: editData.address,
                    connectStatus: editData.connectStatus,
                    createdAt: editData.createdAt,
                    currentCapacity: editData.currentCapacity,
                    description: editData.description,
                    dropBoxIcon: editData.dropBoxIcon,
                    dropStationNumber: editData.dropStationNumber,
                    formattedAddress: locationLength
                        ? locationObj.formatted_address
                        : stationDetails.formatedAddres,
                    geo: editData.geo,
                    images: imageHandler(),
                    latitude: locationLength
                        ? locationObj?.geometry?.location?.lat()
                        : stationDetails.lat,
                    locationId: selectedLocation._id,
                    longitude: locationLength
                        ? locationObj?.geometry?.location?.lng()
                        : stationDetails.lng,
                    macAddress: stationDetails.macAddress,
                    name: stationDetails.name,

                    placeId: locationLength
                        ? locationObj.place_id
                        : stationDetails.place_id,
                    qrCode: stationDetails.qrCode,
                    status: editData.status,
                    totalCapacity: editData.totalCapacity,
                    totalInventoryArr: editData.totalInventoryArr,
                    type: dropActive.label,
                    updatedAt: editData.updatedAt,
                    __v: editData.__v,
                    _id: editData._id,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
                .then((res: any) => {
                    setFlagDisabled(false)
                    setLoading(false)
                    if (res.data.status == 1) {
                        setShow(false)
                        ToastOnSuccess(res.data.message)
                        fetchDropStationData()
                    } else {
                        ToastOnFailure(res.data.message)
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    setFlagDisabled(false)
                    ToastOnFailure(err.message)
                    console.log(err.message)
                })
        } else {
            ToastOnFailure('Please Fill all fields')
        }
    }

    const UploadImgHandler = (imgUrl?: string) => {
        HttpCallPost(
            `s3/upload-file`,
            {
                contentType: 'image/png',
                locationType: 'admin-drop-station',
                image: imgUrl ? imgUrl : imageSrc,
            },
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                ToastOnSuccess(res.data.message)
                setStationDetails({
                    ...stationDetails,
                    imageSrc: res.data.data?.imageUrl,
                })
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    const fetchDropStationData = async () => {
        setLoading(true)
        axios
            .get(
                `${process.env.REACT_APP_BASE_URL}admin/get-all-drop-station`,
                {
                    headers: {
                        Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                    },
                    params: {
                        currentPage: currentPage,
                        pageSize: pageSize,
                        search: searchField,
                    },
                }
            )
            .then((res: any) => {
                setLoading(false)
                setTotal(res.data.totalCount)
                setTableData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    // upload image
    const handleImageUpload = (e: any) => {
        const file = e.target.files[0]
        const reader: any = new FileReader()
        reader.onloadend = () => {
            setImageSrc(reader.result)
            UploadImgHandler(reader.result)
        }
        reader.readAsDataURL(file)
    }

    const columns: any = useMemo(
        () => [
            {
                Header: '',
                accessor: 'images',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            <img
                                className="stationImage"
                                src={item.cell?.value}
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'QR code',
                accessor: 'qrCode',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Connected',
                accessor: 'connectStatus',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value == 0 ? (
                                <i
                                    className="fa fa-circle "
                                    style={{ color: 'red' }}
                                    aria-hidden="true"
                                ></i>
                            ) : (
                                <i
                                    className="fa fa-circle "
                                    style={{ color: 'green' }}
                                    aria-hidden="true"
                                ></i>
                            )}
                        </div>
                    )
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Location',
                accessor: 'locationDetails',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value?.name}
                        </div>
                    )
                },
            },
            {
                Header: 'City',
                accessor: 'address',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.value.city}
                        </div>
                    )
                },
            },
            {
                Header: 'Address',
                accessor: 'address.formattedAddress',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'State',
                accessor: 'address.state',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {' '}
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'type',
                accessor: 'type',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'MAC address',
                accessor: 'macAddress',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Inventory',
                accessor: 'totalInventoryArr',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.value?.length}
                        </div>
                    )
                },
            },
            {
                Header: '',
                accessor: 'contain',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__buttonTable">
                            <CustomButton
                                btnName="Edit"
                                btnEvt={() =>
                                    handleEdit(item?.cell?.row?.original)
                                }
                            >
                                <i className="fa-regular fa-pen-to-square icons"></i>
                            </CustomButton>
                        </div>
                    )
                },
            },
        ],
        []
    )

    // useEffect(() => {
    //     if (imageSrc.length > 0) {
    //         UploadImgHandler()
    //     }
    // }, [imageSrc])

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchDropStationData()
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [currentPage, pageSize, searchField, props.activeUser])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchField])

    const imageHandler = () => {
        return stationDetails?.isUpdate == 'add'
            ? imageSrc
            : stationDetails.imageSrc
    }

    useEffect(() => {
        setLocationObj({})
        if (imgRef.current) {
            setImageSrc('')
            imgRef.current.value = ''
        }
    }, [!show])

    const dataHandler = (data: any) => {
        return (
            data.length > 0 &&
            data.map((item: any) => ({
                ...item,
                terms: item?.termsAndCondition ? true : false,
            }))
        )
    }

    useEffect(() => {
        fetchLocationData()
    }, [])

    return (
        <>
            {' '}
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="station">
                        <SubHeader HeaderTitle="Drop Stations" isShow={false} />
                        <div>
                            <div className="container-fluid">
                                <div className="search_section">
                                    <div className="row">
                                        <div className="SearchAndBtn">
                                            <div className="SerachBar">
                                                <Form.Control
                                                    type="text"
                                                    id="email"
                                                    placeholder="Search"
                                                    value={searchField}
                                                    onChange={(e: any) => {
                                                        setSearchfield(
                                                            e.target.value
                                                        )
                                                    }}
                                                    autoComplete="off"
                                                    autoFocus={true}
                                                />
                                                <i
                                                    className="fa fa-search searchIcon"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                            <div className="text-end">
                                                <CustomButton
                                                    clsName="addStation ms-2 mt-2 mt-md-0"
                                                    btnName="Add Drop Station"
                                                    btnEvt={() =>
                                                        AddDropStation()
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="table-responsive custmizeTable">
                                            <CustomTable
                                                msg="No drop stations found"
                                                columns={columns}
                                                data={tableData || []}
                                            />

                                            <div className="table__pagination__container tablePagination">
                                                <div className="itemPagination">
                                                    {showPagination(
                                                        total,
                                                        pageSize
                                                    ) && (
                                                        <Pagination
                                                            activePage={
                                                                currentPage
                                                            }
                                                            itemsCountPerPage={
                                                                pageSize
                                                            }
                                                            totalItemsCount={
                                                                total
                                                            }
                                                            onChange={(
                                                                page: any
                                                            ) => {
                                                                setCurrentPage(
                                                                    page
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                    {showPageSize(total) && (
                                                        <select
                                                            className="pagination__select"
                                                            value={pageSize}
                                                            onChange={
                                                                handlePageSizeChange
                                                            }
                                                        >
                                                            {[10, 20, 30].map(
                                                                (size) => (
                                                                    <option
                                                                        key={
                                                                            size
                                                                        }
                                                                        value={
                                                                            size
                                                                        }
                                                                    >
                                                                        Show{' '}
                                                                        {size}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                                <div className="perPageItem">
                                                    <p>
                                                        {paginationHandler(
                                                            currentPage,
                                                            pageSize,
                                                            total
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <CustomSlider
                        activeTitle={
                            stationDetails.isUpdate === 'update'
                                ? `Edit`
                                : `Add`
                        }
                        show={show}
                        setShow={setShow}
                        Evt={() =>
                            !flagDisabled &&
                            stationDetails.isUpdate === 'update'
                                ? editStation()
                                : addStation()
                        }
                        btnEventName={
                            flagDisabled
                                ? 'Submiting'
                                : stationDetails.isUpdate == 'update'
                                ? 'update'
                                : 'Add'
                        }
                    >
                        <div className="Disable">
                            <label>Type</label>
                            <CustomDropdown
                                name=""
                                data={DropDownDataValue}
                                activeDrop={dropActive}
                                setActiveDrop={setDropActive}
                                toggleClassName={
                                    stationDetails.isUpdate === 'update'
                                        ? 'disabled'
                                        : ''
                                }
                            />
                        </div>
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Name
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={stationDetails?.name}
                                onChange={(e) =>
                                    setStationDetails({
                                        ...stationDetails,
                                        name: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="QR_Code">
                            <Form.Label htmlFor="inputPassword5">
                                QR code
                            </Form.Label>
                            <Form.Control
                                type="text"
                                value={
                                    stationDetails.qrCode
                                        ? stationDetails.qrCode
                                        : ''
                                }
                                onChange={(e) =>
                                    setStationDetails({
                                        ...stationDetails,
                                        qrCode: e.target.value,
                                    })
                                }
                                className={
                                    stationDetails.isDisable ? 'disable' : ''
                                }
                            />
                        </div>
                        {dropActive.label == 'SMART' ? (
                            <div>
                                <Form.Label htmlFor="inputPassword5">
                                    MAC Address
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    value={
                                        stationDetails.macAddress
                                            ? stationDetails.macAddress
                                            : ''
                                    }
                                    onChange={(e) =>
                                        setStationDetails({
                                            ...stationDetails,
                                            macAddress: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        <div>
                            <Form.Label htmlFor="inputPassword5">
                                Address
                            </Form.Label>
                            <SearchLocationInput
                                setLocationObj={setLocationObj}
                                existingAddress={stationDetails.formatedAddres}
                                locationRef={locationRef}
                                isFlagUpdate={isFlagUpdate}
                                setIsFlagUpdate={setIsFlagUpdate}
                            />
                        </div>
                        <div>
                            <Form.Label>Location</Form.Label>
                            <CustomDropdown
                                name=""
                                data={modifyWrhLocationHandler(locationData)}
                                activeDrop={selectedLocation}
                                setActiveDrop={setSelectedLocation}
                            />
                        </div>
                        <div>
                            <div className="App">
                                <div className="imageModalSection">
                                    <Form.Label htmlFor="inputPassword5">
                                        Add Image
                                    </Form.Label>
                                    <div className="addInput">
                                        <button>Select Image</button>
                                        <input
                                            type="file"
                                            className="uploadInput"
                                            onChange={handleImageUpload}
                                            ref={imgRef}
                                        />
                                    </div>
                                    <div className="uploadImage">
                                        {checkImage() && (
                                            <>
                                                <img
                                                    className="containerType_image"
                                                    src={`${imageHandler()}`}
                                                    alt=""
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomSlider>
                </>
            )}
        </>
    )
}
