import React from 'react'
import { Dropdown } from 'react-bootstrap'
import './dropdown.style.scss'

export interface Option {
    label: string
    value: string
}

interface Props {
    name: string
    data: any
    defaultVal?: any
    setDefaultVal?: any
    parrentClassName?: string
    lebelClassName?: string
    toggleClassName?: string
    menuClassName?: string
    itemClassName?: string
    activeDrop?: any
    setActiveDrop?: any
    isSearchAble?: any
    children?: any
    SearchingData?: any
}

const CustomDropdown: React.FC<Props> = ({
    name,
    data,
    lebelClassName = ``,
    toggleClassName = ``,
    menuClassName = ``,
    itemClassName = ``,
    parrentClassName = ``,
    activeDrop = ``,
    setActiveDrop,
    isSearchAble,
    children,
    SearchingData,

}) => {
    const DataHandler = () => {
        return SearchingData?.length > 0 ? SearchingData : data ? data : []
    }

    // console.log("first",toggleClassName)

    return (
        <div className={`CustomDropdown ${parrentClassName}`}>
            <p className={`CustomDropdown__header ${lebelClassName}`}>{name}</p>
            <Dropdown>
                <Dropdown.Toggle
                    className={`CustomDropdown__toggle ${toggleClassName}`}
                    variant="primary"
                    id={`dropdown-${name}`}
                >
                    {activeDrop?.label ? activeDrop?.label : 'None'}
                </Dropdown.Toggle>

                <Dropdown.Menu className={menuClassName}>
                    {isSearchAble && children}
                    {DataHandler().map((option: any, index: any) => (
                        <Dropdown.Item
                            id={option.label}
                            className={itemClassName}
                            key={option.value}
                            eventKey={option.value}
                            onClick={() => setActiveDrop(option)}
                        >
                            {option?.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default CustomDropdown
