import logo from '../assets/image/newlogo.png'
import whiteLogo from '../assets/image/logo_white.png'
import favicon from '../assets/icons/favicon.png'

const config = {
    logoPath: logo,
    whiteLogoPath: whiteLogo,
    venderTitle: 'Vendors',
    projectTitle: 'Encora::Admin',
    faviconPath: favicon,
}

export default config
