import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import Forget from '../component/Forget/Forget'
import Login from '../component/Login/Login'
import Navbar from '../component/Navbar/Navbar'
import Dashboard from '../pages/dashboard/Dashboard'
import Inventory from '../pages/inventory/Inventory'
import Order from '../pages/order/Order'
import Report from '../pages/report/Report'
import Restaurants from '../pages/Restaurants/restaurants'
import Stations from '../pages/stations/Stations'
import Warehouse from '../pages/warehouse/Warehouse'
import ProtectedRoute from './ProtectedRoute'
import PublicRoute from './PublicRoute'
import CharityList from '../pages/Programs/CharityList'
import Claims from '../pages/Programs/Claims'
import Donations from '../pages/Programs/Donations'
import Locations from '../pages/Location/Locations'
import User from '../pages/User/User'
import ContainerType from '../pages/ContainerType/ContainerType'
import RestaurantCategory from '../pages/RestaurantCategory/RestaurantCategory'
import Program from '../pages/Programs/Program/Program'
import Footer from '../component/Footer/Footer'
import LocationUser from '../pages/LocationUser/LocationUser'
import PendingContainer from '../pages/PendingContainer/PendingContainer'
import Admin from '../pages/Admin/Admin'
import AdminLocationList from '../pages/AdminLocation/AdminLocationList'
import AdminProfile from '../pages/AdminProfile/AdminProfile'
import InventoryManagement from '../pages/InventoryManagement/InventoryManagement'

const RoutePath = () => {
    const [activeUser, setActiveUser] = useState(0)
    const navigate = useNavigate()

    const [isVerified, setVerified] = useState(
        sessionStorage.getItem('AUTH_TOKEN') ? true : false
    )
    const setSigninStatus = (status: boolean) => {
        setVerified(status)
        if (status) {
            navigate('/dashboard')
        }
    }

    return (
        <div>
            {isVerified ? (
                <>
                    <Navbar
                        activeUser={activeUser}
                        setActiveUser={setActiveUser}
                        setSigninStatus={setSigninStatus}
                    />
                </>
            ) : (
                <></>
            )}
            <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route
                    path="/login"
                    element={
                        <PublicRoute
                            isVerified={isVerified}
                            redirectPath="/dashboard"
                        >
                            <Login setSigninStatus={setSigninStatus} />
                        </PublicRoute>
                    }
                ></Route>

                <Route
                    path="/forget-password"
                    element={
                        <PublicRoute
                            isVerified={isVerified}
                            redirectPath="/dashboard"
                        >
                            <Forget setSigninStatus={setSigninStatus} />
                        </PublicRoute>
                    }
                ></Route>
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Dashboard activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/warehouse"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Warehouse activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/inventory"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Inventory activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/dropStations"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Stations activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/restaurants"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Restaurants activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/charity-list"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <CharityList activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/claimed-rewards"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Claims activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/donated-rewards"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Donations activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/orders"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Order activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/location-dropStations"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <AdminLocationList activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/checkedInReport"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Report activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/Pending-contianers"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <PendingContainer activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/locations"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Locations activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/Users"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <User activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/location-user"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <LocationUser activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/container-type"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <ContainerType activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/restaurant-category"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <RestaurantCategory activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>

                <Route
                    path="/reward-list"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Program activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <Admin activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <AdminProfile activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
                <Route
                    path="/inventory-management"
                    element={
                        <ProtectedRoute
                            isVerified={isVerified}
                            redirectPath="/login"
                        >
                            <InventoryManagement activeUser={activeUser} />
                        </ProtectedRoute>
                    }
                ></Route>
            </Routes>
            <Footer />
        </div>
    )
}

export default RoutePath
