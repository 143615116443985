import React, { useEffect, useMemo, useRef, useState } from 'react'
import Loader from '../../utils/Loader/Loader'
import { Form } from 'react-bootstrap'
import CustomTable from '../../utils/CustomTable/CustomTable'
import Pagination from 'react-js-pagination'
import '../inventory/inventory.style.scss'
import axios from 'axios'
import {
    paginationHandler,
    showPageSize,
    showPagination,
} from '../../utils/util'
import SubHeader from '../../component/subHeader/SubHeader'

function AdminLocationList(props: any) {
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState<any>(0)
    const [currentPage, setCurrentPage] = useState<any>(1)
    const [pageSize, setPageSize] = useState<any>(10)
    const [searchField, setSearchfield] = useState('')
    const [tableData, setTableData] = useState<any>([])

    const { _id } = JSON.parse(`${sessionStorage.getItem('locationStore')}`)

    function handlePageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setCurrentPage(1)
        setPageSize(Number(e.target.value))
        setCurrentPage(1)
    }

    const fetchDropStationData = async () => {
        setLoading(true)
        axios
            .get(`${process.env.REACT_APP_BASE_URL}admin/get-drop-locations`, {
                headers: {
                    Authorization: sessionStorage.getItem('AUTH_TOKEN'),
                },
                params: {
                    currentPage: currentPage,
                    pageSize: pageSize,
                    locationId: _id,
                    search: searchField,
                },
            })
            .then((res: any) => {
                setLoading(false)
                setTotal(res.data.totalLocation)
                setTableData(res.data.data)
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const columns: any = useMemo(
        () => [
            {
                Header: '',
                accessor: 'images',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            <img
                                className="stationImage"
                                src={item.cell?.value}
                            />
                        </div>
                    )
                },
            },
            {
                Header: 'QR code',
                accessor: 'qrCode',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Connected',
                accessor: 'connectStatus',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value == 0 ? (
                                <i
                                    className="fa fa-circle "
                                    style={{ color: 'red' }}
                                    aria-hidden="true"
                                ></i>
                            ) : (
                                <i
                                    className="fa fa-circle "
                                    style={{ color: 'green' }}
                                    aria-hidden="true"
                                ></i>
                            )}
                        </div>
                    )
                },
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'City',
                accessor: 'address',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.value.city}
                        </div>
                    )
                },
            },
            {
                Header: 'Address',
                accessor: 'address.formattedAddress',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'State',
                accessor: 'address.state',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {' '}
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'type',
                accessor: 'type',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'MAC address',
                accessor: 'macAddress',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item.cell?.value}
                        </div>
                    )
                },
            },
            {
                Header: 'Inventory',
                accessor: 'totalInventoryArr',
                Cell: (item: any) => {
                    return (
                        <div className="Inventory__tabTableImg">
                            {item?.value?.length}
                        </div>
                    )
                },
            },
        ],
        []
    )

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchDropStationData()
        }, 500)
        return () => {
            clearTimeout(timer)
        }
    }, [currentPage, pageSize, searchField, props.activeUser])

    useEffect(() => {
        setCurrentPage(1)
    }, [searchField])

    return (
        <>
            {' '}
            {loading ? (
                <Loader startLoader={loading} />
            ) : (
                <>
                    <div className="station">
                        <SubHeader HeaderTitle="Drop Stations" />
                        <div>
                            <div className="container-fluid">
                                <div className="search_section">
                                    <div className="row">
                                        <div className="SearchAndBtn">
                                            <div className="SerachBar">
                                                <Form.Control
                                                    type="text"
                                                    id="email"
                                                    placeholder="Search"
                                                    value={searchField}
                                                    onChange={(e: any) => {
                                                        setSearchfield(
                                                            e.target.value
                                                        )
                                                    }}
                                                    autoComplete="off"
                                                    autoFocus={true}
                                                />
                                                <i
                                                    className="fa fa-search searchIcon"
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="table-responsive custmizeTable">
                                            <CustomTable
                                                msg="No drop stations found"
                                                columns={columns}
                                                data={tableData || []}
                                            />

                                            <div className="table__pagination__container tablePagination">
                                                <div className="itemPagination">
                                                    {showPagination(
                                                        total,
                                                        pageSize
                                                    ) && (
                                                        <Pagination
                                                            activePage={
                                                                currentPage
                                                            }
                                                            itemsCountPerPage={
                                                                pageSize
                                                            }
                                                            totalItemsCount={
                                                                total
                                                            }
                                                            onChange={(
                                                                page: any
                                                            ) => {
                                                                setCurrentPage(
                                                                    page
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                    {showPageSize(total) && (
                                                        <select
                                                            className="pagination__select"
                                                            value={pageSize}
                                                            onChange={
                                                                handlePageSizeChange
                                                            }
                                                        >
                                                            {[10, 20, 30].map(
                                                                (size) => (
                                                                    <option
                                                                        key={
                                                                            size
                                                                        }
                                                                        value={
                                                                            size
                                                                        }
                                                                    >
                                                                        Show{' '}
                                                                        {size}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                                <div className="perPageItem">
                                                    <p>
                                                        {paginationHandler(
                                                            currentPage,
                                                            pageSize,
                                                            total
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default AdminLocationList
