export const LOGIN = 'admin/signIn'
export const FORGET_PASSWORD = 'admin/forgot-password'
export const WRH_ADD_ITEM = 'admin/warehouse/create'
export const LOGOUT = 'admin/logOut'
export const DASHBOARD_BARCHART = 'admin/bar-chart-data'
export const DASHBOARD_PENDING = 'admin/totalPendingOrders'
export const DASHBOARD_PAI_CHART = 'admin/pie-chart-data'
export const ADDWEB_INVENTORY = 'admin/addWeb-inventory'
export const GET_DROP_LOCATION = 'admin/get-drop-locations'
export const CONTAINER_TYPE_CHANGE = 'admin/container-type-change'
export const ADDWEB_INVENTORY_MANAGEMENT = 'admin/addWeb-inventory'
