import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { ToastOnSuccess, ToastOnFailure } from '../../utils/Toast/Toast'
import { HttpCallGet, HttpCallPUT } from '../../utils/Services/NetworkCall'
import { Formik, Field, ErrorMessage, FormikHelpers } from 'formik'
import { validationSchema } from './helper'
import './AdminProfile.style.scss'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'

interface AdminProfileProps {
    data?: any
    activeUser: any
}

const AdminProfile: React.FC<AdminProfileProps> = ({ data, activeUser }) => {
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const [userDetail, setUserDetail] = useState({
        firstName: '',
        lastName: '',
        email: '',
    })

    const initialValue = {
        firstName: userDetail.firstName || '',
        lastName: userDetail.lastName || '',
        email: userDetail.email || '',
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    }

    const fetchAdminData = (adminId: string) => {
        setLoading(true)
        HttpCallGet(
            `admin/get-admin/${adminId}`,
            sessionStorage.getItem('AUTH_TOKEN')
        )
            .then((res: any) => {
                const { firstName, lastName, email } = res.data.data
                setLoading(false)
                console.log('res', res)
                setUserDetail({
                    firstName,
                    lastName,
                    email,
                })
            })
            .catch((err) => {
                setLoading(false)
                console.log(err.message)
            })
    }

    const onSubmit = async (
        values: any,
        { setSubmitting }: FormikHelpers<any>
    ) => {
        setSubmitting(true)

        const {
            firstName,
            lastName,
            currentPassword,
            newPassword,
            confirmPassword,
            email,
        } = values

        try {
            const response: any = await HttpCallPUT(
                `admin/update-admin`,
                {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    oldPassword: currentPassword,
                    newPassword: newPassword,
                    confirmPassword: confirmPassword,
                },
                sessionStorage.getItem('AUTH_TOKEN')
            )
            console.log('response', response)
            if (response.data.status === 1) {
                ToastOnSuccess(response.data.message)
                navigate('/dashboard')
            } else {
                ToastOnFailure(response.data.message)
            }
        } catch (error: any) {
            setLoading(false)
            console.error('Error:', error.message)
            ToastOnFailure(error.message)
        } finally {
            setSubmitting(false)
        }
    }

    useEffect(() => {
        const userData = JSON.parse(
            sessionStorage.getItem('userDetails') || '{}'
        )
        if (userData?._id) {
            fetchAdminData(userData?._id)
        }
    }, [])

    return (
        <div className="container">
            <div className="Form mt-4 mb-5">
                <Formik
                    initialValues={initialValue}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        onSubmit(values, actions)
                    }}
                >
                    {({ errors, touched, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Field
                                    type="text"
                                    name="firstName"
                                    className={`form-control ${
                                        touched.firstName && errors.firstName
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="firstName"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Field
                                    type="text"
                                    name="lastName"
                                    className={`form-control ${
                                        touched.lastName && errors.lastName
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="lastName"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Field
                                    type="email"
                                    name="email"
                                    className={`form-control ${
                                        touched.email && errors.email
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="email"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Current Password</Form.Label>
                                <Field
                                    type="password"
                                    name="currentPassword"
                                    className={`form-control ${
                                        touched.currentPassword &&
                                        errors.currentPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="currentPassword"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Field
                                    type="password"
                                    name="newPassword"
                                    className={`form-control ${
                                        touched.newPassword &&
                                        errors.newPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="newPassword"
                                    className="invalid-feedback"
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Label>Confirm Password</Form.Label>
                                <Field
                                    type="password"
                                    name="confirmPassword"
                                    className={`form-control ${
                                        touched.confirmPassword &&
                                        errors.confirmPassword
                                            ? 'is-invalid'
                                            : ''
                                    }`}
                                />
                                <ErrorMessage
                                    component="div"
                                    name="confirmPassword"
                                    className="invalid-feedback"
                                />
                            </div>

                            <div className="mb-3">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={loading}
                                >
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default AdminProfile
